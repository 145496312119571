import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useAppRouter = (nextRoute?: string) => {
  const router = useRouter();

  const getDefaultQueryParams = () => {
    const { query } = router;
    const params: Record<string, string> = {};

    if (query.flowName) {
      params.flowName = query.flowName as string;
    }

    return params;
  };

  useEffect(() => {
    if (nextRoute && typeof nextRoute === 'string') {
      router.prefetch(nextRoute);
    }
  }, [router, nextRoute]);

  return {
    ...router,
    pushNextRoute: (queryParams?: Record<string, string | undefined>) => {
      const defaultQueryParams = getDefaultQueryParams();

      if (nextRoute) {
        router.push(
          queryParams || defaultQueryParams
            ? { pathname: nextRoute, query: { ...defaultQueryParams, ...queryParams } }
            : nextRoute,
        );
      }
    },
    flowName: router.query.flowName as undefined | string,
    isLocaleDefault: router.locale === 'en-US',
    isPathCheckout: /\/checkout$/.test(router.asPath),
    isPathPlans: /\/plans$/.test(router.asPath),
    isPathShipping: /\/shipping$/.test(router.asPath),
  };
};
